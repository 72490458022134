
import { store } from '@/assets/scripts/store'

export default {
  name: 'ApiEnumCAMap',

  props: {
    apiData: { type: Object, default: () => {} },
  },

  data() {
    return {
      itemOpenName: '',
      itemOverName: '',
    }
  },

  computed: {
    isTouchDevice() {
      return store.touchDevice
    },
  },

  methods: {
    over(id) {
      if (!this.isTouchDevice) {
        this.itemOpenName = id
        this.itemOverName = id
      }
    },

    out(id) {
      if (!this.isTouchDevice && this.itemOpenName === id) {
        this.itemOpenName = null
        this.itemOverName = null
      }
    },
  },
}
